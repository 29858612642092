.question {
    background: #fefefe;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    max-width: 90vw;

    p{
        color: #29292e;
    }

    img{
        width: 350px;
        height: 350px;
        max-width: 350px;
        object-fit: cover;
    }

    @media only screen and (max-width: 400px) {
        img {
            width: 300px;
            height: 300px;
            object-fit: cover;
        }
    }

    @media only screen and (max-width: 320px) {
        img {
            width: 250px;
            height: 250px;
            object-fit: cover;
        }
    }

    

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    

        .user-info{
            display: flex;
            flex-direction: column;
            max-width: 240px;
        }

        span{
            margin-left: 8px;
            margin-top: 4px;
            color: #737380;
            font-size: 16px;
        }

        
    }

    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        
    

        .user-info{
            display: flex;
            flex-direction: column;
            max-width: 240px;

            img{
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }

            span{
                margin-left: 8px;
                margin-top: 4px;
                color: #737380;
                font-size: 16px;
            }
        }

        .captcha-info{
            display: flex;
            flex-direction: column;
            align-items: center;
            
            button{
                margin: 5px;
            }
        }

        button {
            transition: 0.2s;
        

            &.like-button {
                display: flex;
                align-items: flex-end;
                color:#737380;

                svg {
                    margin-left: 8px;
                }

                &.liked {
                    color: #835afd;

                    svg path {
                        stroke: #835afd;
                    }
                }
            }

            &:hover {
                filter: brightness(0.6);
            }
        }
    }
}